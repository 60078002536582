export class HelperMethods {
  // Method to append the redirect url
  static urlWithRedirect(url: string) {
    return url + '?back=' + encodeURIComponent(window.location.href)
  }

  static updateRouteHash(route: string) {
    const newRoute = window.location.pathname + route
    history.pushState({}, '', newRoute)
  }

  static routeWithHash(route: string) {
    const newRoute = window.location.pathname + route
    return newRoute
  }

  static paginationText(
    pageNumber: number,
    totalCount: number,
    rowCount: number = 10,
  ) {
    const startIndex = pageNumber + 1
    const endIndex = Math.min(pageNumber + rowCount, totalCount)
    const totalItems = totalCount
    return `Showing ${startIndex}-${endIndex} of ${totalItems}`
  }

  static capitalizeAllWords(str: string): string {
    return str.replace(/\b(\w)/g, (match) => match.toUpperCase())
  }

  static valueDifference(
    expectedValue: string | number,
    actualValue: string | number,
    toFixedValue: number,
  ): number {
    const difference = (
      Math.abs(Number(expectedValue) - Number(actualValue)) * 100
    ).toFixed(toFixedValue)

    return Number(difference)
  }

  static isObjectEmpty(obj: Record<string, any>): boolean {
    return Object.keys(obj).length === 0
  }

  static isDifferentDomain(link: string) {
    const url = new URL(link)
    return url.origin !== window.location.origin
  }

  // static getNotificationDateDiff(date: Date) {
  //   const today: Date = new Date()
  //   const diff = Math.floor(
  //     (new Date(today) - new Date(date)) / (1000 * 60 * 60 * 24),
  //   )
  //   switch (true) {
  //     case diff === 0:
  //       return 'Today'
  //     case diff <= 7:
  //       return `${diff}d`
  //     case diff <= 14:
  //       return '2w'
  //     case diff <= 21:
  //       return '3w'
  //     case diff <= 28:
  //       return '4w'
  //     default:
  //       return new Date(date).toLocaleDateString('en-US', {
  //         month: 'short',
  //         day: 'numeric',
  //       })
  //   }
  // }

  static setRouteHash(route: any, hashPath: string, _router: any) {
    hashPath = hashPath.replaceAll(' ', '_')
    const newRoute = route.path + '#' + hashPath
    // TODO: Should replace history.pushstate with $router.replace
    // With history.pushState actual router object is not updated.
    history.pushState({}, '', newRoute)
    // if (router) {
    //   // Replacing the router hash
    //   router.replace({
    //     path: route.path,
    //     hash: '/#' + hashPath,
    //   })
    // }
  }

  static timeZoneAbbreviated() {
    const { 1: timeZoneName } = new Date().toString().match(/\((.+)\)/)
    if (timeZoneName.includes(' ')) {
      return timeZoneName
        .split(' ')
        .map(([firstChar]) => firstChar)
        .join('')
    } else {
      return timeZoneName
    }
  }

  static initializeGtag(userId: string, shareKey: string | null = '') {
    window.dataLayer = window.dataLayer || []
    function gtag(...args: any[]) {
      window.dataLayer.push(args)
    }
    gtag('set', { user_id: userId })
    gtag('set', { share_key: shareKey })
  }
}
