<!-- eslint-disable vue/no-v-html -->
<template>
  <div class="error">
    <div class="wrapper">
      <div
        v-if="error && (error.statusCode === 401 || error.statusCode === 403)"
      >
        <h4 class="title-lg py-0 my-2">Access Denied</h4>
        <span class="text-lg">
          You do not have access to Console. Please request for access.
        </span>
        <br />
        <Button label="Retry" class="cta-button-yellow" @click="onGoBack()" />
      </div>
      <div v-else class="px-5">
        <h4 class="title-lg py-0 my-2">Error</h4>
        <br />
        <span class="text-lg">
          {{ error.statusCode }}
        </span>
        <br />
        <div class="text-lg" v-html="error.message"></div>
        <br />
        <button class="btn-default cta-button-yellow" @click="onGoBack()">
          Go back
        </button>
      </div>
      <div class="px-5">
        <img src="/images/webpage-not-found.png" alt="Not found" />
      </div>
    </div>
  </div>
</template>

<script setup>
  // Component Input Props
  defineProps({ error: Error })

  // Store
  const appStore = useAppStore()

  // Methods and Event Handlers
  const onGoBack = async () => {
    await appStore.fetchUser('all').then(() => {
      navigateTo('/')
    })
  }
</script>
<style lang="scss" scoped>
  .error {
    & .wrapper {
      height: 100vh;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    :deep(.cta-button-yellow) {
      font-size: 16px !important;
    }
  }
</style>
