export const appHead = {"meta":[{"charset":"utf-8"},{"name":"viewport","content":"width=device-width, initial-scale=1"},{"hid":"description","name":"description","content":"For McKesson Compile customers: Access your McKesson Compile data, data dictionary, release notes, FAQs and more."},{"hid":"og:title","property":"og:title","content":"McKesson Compile Console"},{"hid":"og:site_name","property":"og:site_name","content":"console.compile.com"},{"hid":"og:description","property":"og:description","content":"For McKesson Compile customers: Access your McKesson Compile data, data dictionary, release notes, FAQs and more."},{"property":"og:image","content":"/og-mck-compile-console.png"}],"link":[{"rel":"icon","type":"image/x-icon","href":"/favicon.ico"},{"rel":"stylesheet","href":"https://cdn.jsdelivr.net/npm/bootstrap-icons@1.7.1/font/bootstrap-icons.css"},{"rel":"stylesheet","href":"https://fonts.googleapis.com/css2?family=Libre+Franklin:ital,wght@0,100..900;1,100..900&display=swap"}],"style":[],"script":[{"src":"https://public.tableau.com/javascripts/api/tableau-2.min.js","type":"text/javascript","body":true},{"src":"https://www.googletagmanager.com/gtag/js?id=G-YRSX4S33DQ","async":true}],"noscript":[],"title":"McKesson Compile Console"}

export const appPageTransition = {"name":"fade","mode":"out-in"}

export const appLayoutTransition = {"name":"slide","mode":"out-in"}

export const appBaseURL = "/"

export const appBuildAssetsDir = "/_nuxt/"

export const appCdnURL = ""

export const appViewTransition = true

export const appKeepalive = false

export const appRootId = "__nuxt"

export const appRootTag = "div"

export const appRootAttrs = {"id":"__nuxt"}

export const appTeleportTag = "div"

export const appTeleportId = "teleports"

export const appTeleportAttrs = {"id":"teleports"}

export const renderJsonPayloads = true

export const componentIslands = false

export const payloadExtraction = true

export const cookieStore = true

export const appManifest = true

export const remoteComponentIslands = false

export const selectiveClient = false

export const devPagesDir = null

export const devRootDir = null

export const devLogs = false

export const nuxtLinkDefaults = {"componentName":"NuxtLink","prefetch":true,"prefetchOn":{"visibility":true}}

export const asyncDataDefaults = {"value":null,"errorValue":null,"deep":true}

export const resetAsyncDataToUndefined = true

export const nuxtDefaultErrorValue = null

export const fetchDefaults = {}

export const vueAppRootContainer = '#__nuxt'

export const viewTransition = true

export const appId = "nuxt-app"

export const outdatedBuildInterval = 3600000

export const multiApp = false

export const chunkErrorEvent = "vite:preloadError"