import { type AxiosResponse } from 'axios'
import BaseService from '@/services/base-service'
import type { IUser } from '~/stores/types/app'

export default class AppService extends BaseService {
  fetchUser = async (noLoader: boolean): Promise<IUser> => {
    const response: AxiosResponse<IUser> = await this.http.get(
      '/api/v1/users/me/',
      { noLoader },
    )
    return response?.data
  }

  versionCheck = async (): Promise<string> => {
    const url = window.location.protocol + '//' + window.location.host + '/'
    const response: AxiosResponse<string> = await this.http.get(url, {
      noLoader: true,
    })
    return response?.data
  }
}
