<template>
  <div class="app">
    <!-- <NuxtLayout
      v-if="hasUserInfo"
      :name="
        hasShareKey && hasUserInfo
          ? 'default'
          : hasShareKey
            ? 'no-auth'
            : 'default'
      "
    > -->

    <NuxtLayout v-if="!showAuthLoader">
      <NuxtPage />
    </NuxtLayout>
    <div v-else class="auth-loader">
      <div v-if="!appStore.isNoAuthAllowed()" class="auth-loader__container">
        <h3 class="title-lg">
          Please wait while the authentication is being processed
        </h3>
        <LoaderEllipsis height="50px" />
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
  import LoaderEllipsis from './components/LoaderEllipsis.vue'

  // Store
  const appStore = useAppStore()

  // Properties
  const showAuthLoader: Ref<boolean> = ref(false)

  // Computed Properties

  // Hooks
  onMounted(async () => {
    const route = useRoute()
    if (route.path.includes('maintenance')) {
      return
    }
    // Authuntication on load of Application
    if (
      !appStore.isNoAuthAllowed() &&
      HelperMethods.isObjectEmpty(appStore.user)
    ) {
      showAuthLoader.value = true
      await appStore.fetchUser('all').finally(() => {
        showAuthLoader.value = false
      })
    } else if (appStore.isNoAuthAllowed()) {
      appStore.fetchUser('all', true)
      // No auth allowed layouts should show the contact us button
      appStore.setEmbedPublicUser(true)
    }

    // New version check
    versionCheck()
    setInterval(versionCheck, 60000)
  })

  // Methods and Event handlers
  const versionCheck = () => {
    // Assumes the app is SPA, and the index page js file hashes change
    appStore.fetchVersion()
  }
</script>
<style lang="scss" scoped>
  @import '@/assets/scss/_color-palette.scss';
  .app {
    background-color: $dark-blue;
  }
  .layout-enter-active,
  .layout-leave-active {
    transition: all 0.2s;
  }

  .layout-enter-from,
  .layout-leave-to {
    filter: grayscale(1);
  }

  .page-enter-active,
  .page-leave-active {
    transition: all 0.2s;
  }

  .page-enter-from,
  .page-leave-to {
    opacity: 0;
    filter: blur(1rem);
  }

  .auth-loader {
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    &__container {
      padding: 2.5rem;
      background-color: $white;
      border-radius: 0.5rem;
      text-align: center;
      h3 {
        color: $dark-blue;
        margin-bottom: 0;
      }
    }
  }
</style>
