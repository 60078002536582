export default defineNuxtPlugin(() => {
  window.dataLayer = window.dataLayer || []
  function gtag(...args: any[]): void {
    window.dataLayer.push(args)
  }
  gtag('js', new Date())
  gtag('config', useRuntimeConfig().public.googleAnalyticsId as string)
})

declare global {
  interface Window {
    dataLayer: any[]
  }
}
